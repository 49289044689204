@import '../src/assets/css/app.min.css';
@import '../src/assets/css/vendor/animate.min.css';
@import '../src/assets/css/vendor/dataTables.bootstrap4.css';
@import '../src/assets/css/vendor/responsive.bootstrap4.css';
@import '../src/assets/css/vendor/buttons.bootstrap4.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~summernote/dist/summernote-lite.min.css";
@import '~flatpickr/dist/flatpickr.css';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
/*@import '~simplebar/dist/simplebar.css';*/

body,
.apex-charts text,
.apexcharts-tooltip-text,
.apexcharts-tooltip-title,
.apexcharts-legend-text,
.apexcharts-xaxis text,
.apexcharts-yaxis text,
.britechart,
.control-label,
.daterangepicker,
.jq-toast-single,
.jq-toast-single h2,
.jqsfield,
.jvectormap-label,
.ng2-tag-input tag,
.popover,
.tick text,
.tooltip,
.tt-highlight {
    font-family: Nunito, sans-serif !important;
}

.overflow-visible {
    overflow: visible;
}

body {
    min-width: 100vw !important;
    min-height: 100vh !important;
}

body.public-page {
    /*background: #fff !important;*/
    /*padding-bottom: 60px;*/
}

body.modal-open {
    padding: 0 !important;
}

.ngx-position-absolute.ngx-overlay {
    height: 100vh !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

.wrapper {
    padding-top: 75px;
}

@media (min-width: 992px) {
    .wrapper {
        padding-top: 70px;
    }
}

.btn:not(.btn-icon):not(.btn-link) {
    min-width: 180px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.form-control:disabled.style-default, .form-control[readonly].style-default {
    background-color: #ffffff;
}

.input-group-append-over {
    position: absolute;
    right: 15px;
    margin-top: 1px;
    z-index: 3;
}

.btn-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.btn-nplr {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.btn.disabled,
.btn:disabled {
    opacity: .2;
    pointer-events: none;
}

.topnav-navbar {
    z-index: 9;
}

.topnav {
    position: fixed;
    left: 0;
    top: 70px;
    width: 100%;
    z-index: 6;
}

.btn-underline {
    background: transparent;
    border-color: transparent;
    text-decoration: underline;
}

.btn-underline:hover {
    text-decoration: none;
    background: transparent;
    border-color: transparent;
}

.btn-underline.btn-danger {
    color: #fa5c7c;
}

.btn {
    box-shadow: none !important; /* vypnout shadows ? */
}

.card.card-bordered {
    border: 2px #eef2f7 solid;
}

.card.card-link:hover {
    cursor: pointer;
}

.card.card-link:hover .link-text {
    text-decoration: underline;
}

.btn-icon {
    font-size: 28px;
    padding: 0 !important;
    width: 45px !important;
    height: 45px !important;
}


.btn:not(.btn-sm):not(.btn-lg):not(.btn-xs) {
    padding: .7rem 3.5rem;
}

.dropdown-toggle::after {
    border: none
}

.btn-white:hover {
    background: #eef2f7;
    border-color: #eef2f7;
}


.ck-editor__editable_inline {
    min-height: 100px;
}

.ck.ck-editor {
    color: #000;
}

.control-label {
    color: #3D3F49;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 16px;
}

.form-control {
    height: 45px;
    border: 2px solid #DFE2E6;
    border-radius: 3px;
    color: #000;
}

.ng-select.custom .ng-select-container {
    min-height: 45px;
    border: 2px solid #DFE2E6;
    border-radius: 3px;
    font-size: 16px;
}

.ng-select.ng-select-multiple.custom .ng-select-container .ng-value-container .ng-placeholder {
    position: static;
}


.form-control input[type=date],
.form-control input[type=datetime-local],
.form-control input[type=month],
.form-control input[type=time] {
    -webkit-appearance: none;
}

.form-control input[type=date] div,
.form-control input[type=datetime-local] div,
.form-control input[type=month] div,
.form-control input[type=time] div {
    text-align: left;
}


.tooltip {
    z-index: 7;
}

.form-control {
    font-size: 16px;
}

/* input for titles */
.title-form-control {
    font-size: 1.5em;
}

.invisible-form-control {
    /* padding-left: 0; */
    /* padding-right: 0; */
}

.invisible-form-control:not(:focus):not(:hover) {
    /* border-color: transparent; */
    border-color: #f9f9f9;
}

.priority-1 {
    color: rgb(86, 163, 101);
}

.priority-2 {
    color: rgb(13, 135, 39);
}

.priority-3 {
    color: rgb(242, 125, 52);
}

.priority-4 {
    color: rgb(244, 69, 73);
}

.priority-5 {
    color: rgb(215, 2, 20);
}

.badge-priority-1 {
    color: rgb(86, 163, 101);
    background-color: rgba(86, 163, 101, .5);
}

.badge-priority-2 {
    color: rgb(13, 135, 39);
    background-color: rgba(13, 135, 39, .5);
}

.badge-priority-3 {
    color: rgb(242, 125, 52);
    background-color: rgba(242, 125, 52, .5);
}

.badge-priority-4 {
    color: rgb(244, 69, 73);
    background-color: rgba(244, 69, 73, .5);
}

.badge-priority-5 {
    color: rgb(215, 2, 20);
    background-color: rgba(215, 2, 20, .5);
}

.badge-priority-6 {
    color: rgb(255, 0, 0);
    background-color: rgba(215, 2, 20, .5);
}

.ng2-tag-input {
    height: 45px;
    border: 2px solid #DFE2E6 !important;
    border-radius: 3px;
    padding: 2px 0 0 3px !important;
}

.ng2-tag-input--focused {
    border-color: rgb(192, 201, 208);
}

.ng2-tag-input tag {
    border-radius: 4px !important;
    font-family: Nunito, sans-serif !important;
    font-size: .9em !important;
}

.flex-1 {
    flex: 1;
}

label {
    margin-bottom: 1rem;
}

.nav-tabs.nav-bordered li a {
    padding: 1rem 1.25rem;
}

.dropzone {
    border: 2px solid #dee2e6;
    background: rgb(246, 247, 248);
    padding-bottom: 3px !important;
}

/*
  tables
  */
.table .thead-light th {
    background-color: transparent;
    border: none;
    color: #26272B;
}

.dataTables_empty {
    display: none;
}

textarea {
    max-height: 300px;
    min-height: 45px;
}

.dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 30%;
    height: 40px;
    margin-left: -20%;
    margin-top: -25px;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 10px !important;
    line-height: 40px !important;
    padding: 0 !important;
    -webkit-box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.75);
}

.dataTables_paginate .pagination {
    margin-top: 1.5rem !important;
}

.dataTables_paginate .pagination > li.previous > a {
    padding: 12px 27px 12px 27px;
}

.dataTables_paginate .pagination > li > a.page-link {
    padding: 12px 17px 12px 17px;
}

.dataTables_paginate .pagination > li.next > a.page-link {
    padding: 12px 27px 12px 27px;
}

.table td, .table th {
    border-top: none;
    border-bottom: 1px solid #eef2f7;
}

.border-bottom {
    border-bottom: 1px solid #eef2f7 !important;
}

@media (max-width: 768px) {
    .dataTables_paginate li.paginate_button.next,
    .dataTables_paginate li.paginate_button.previous {
        display: inline-block;
        font-size: 1rem;
    }
}


.modal-body {
    padding: 2rem;
}

.modal-header {
    padding: 2rem;
}

.modal-content {
    border-radius: 0.5rem;
}

.validation-text {
    font-size: .8rem;
    margin-top: 8px;
}

.topnav-logo-sm {
    position: relative;
    margin-left: 10px;
}

.topnav-logo-sm img {
    position: relative;
    top: -10px !important;
}

.topnav-logo-sm .topnav-logo-subtitle {
    display: block !important;
    line-height: 15px !important;
    position: absolute !important;
    left: 0 !important;
    bottom: 15px !important;
    top: auto !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.topnav-logo-sm .topnav-logo-subtitle:before {
    display: none !important;
}


.submenu-dropdown .btn {
    padding: 0 1.5rem !important;
    line-height: 50px;
}

.submenu-dropdown .btn {
    color: #000 !important;
    background: #fff !important;

    border-color: transparent !important;
    text-align: left;

}

.submenu-dropdown .btn:hover,
.submenu-dropdown .btn:focus {
    box-shadow: none;
    background: #f9f9f9;
}

.submenu-dropdown .dropdown-menu {
    border: 2px #dee2e6 solid;
    padding: .5rem 1.5rem;
    margin-top: 15px !important;
}

.submenu-dropdown .dropdown-menu .btn {
    border-bottom: 1px #f2f3f5 solid !important;
    color: #909598 !important;
    background: #fff !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 0 !important;
}

.submenu-dropdown .dropdown-menu .btn:hover,
.submenu-dropdown .dropdown-menu .btn.active {
    color: #000 !important;
}

.submenu-dropdown .dropdown-menu .btn:last-of-type {
    border-bottom: none !important;
}

.nav-user {
    border: none;
    background-color: #fff;
    padding: calc(50px / 2) 20px calc(31px / 2) 57px !important;
}

.nav-user .account-user-name {
    font-weight: 400;
    color: #383b4a
}

.required-label:after {
    content: " *";
    color: red;
}

.color-box {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: inline-block;
}

@media (max-width: 576px) {
    .ng2-tag-input {
        height: 150px !important;
    }
}

.alert {
    display: inline-block;
    margin-bottom: 0;
}


.fixed-alert-wrap {
    position: fixed;
    text-align: center;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    left: 0;

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.fixed-alert-wrap .alert {
    margin-top: 15px;
}

@media (max-width: 586px) {
    .fixed-alert-wrap .alert {
        font-size: .8em;
    }

    .fixed-alert-wrap .alert .close {
        padding: .25rem .5rem;
    }
}

@media (max-width: 681px) {
    .fixed-alert-wrap .alert {
        font-size: .9em;
    }
}

@media (max-width: 1000px) {
    .fixed-alert-wrap {
        width: auto;
        right: 80px;
    }

    .fixed-alert-wrap .alert {
        text-align: left;
        padding: .5rem .5rem .5rem .5rem;
    }

    .fixed-alert-wrap .alert.alert-dismissible {
        padding-right: 2.5rem;
    }
}

@media (min-width: 1001px) {
    .fixed-alert-wrap {
        width: 780px;
        left: 50%;
        margin-left: -390px;
    }
}

u {
    cursor: pointer;
}

.btn-outline-secondary {
    border: 2px solid #DFE2E6 !important;
    border-radius: 3px !important;
}

.progress {
    height: 0.5rem;
}

.btn-outline-secondary:hover {
    background-color: #DFE2E6 !important;
}


.card-help {
    position: absolute;
    right: -12px;
    top: -14px;
    color: #6979ed;
    opacity: .4;
    transition: opacity .3s;
}

.card-help .btn {
    height: auto !important;
    width: auto !important;
    line-height: 1 !important;
}

.card-help i.mdi {
    line-height: 1;
    cursor: pointer;
}

.card:hover .card-help {
    opacity: 1;
}

.card.notify-dot .card-help {
    top: 14px;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-1 {
    opacity: 0.2 !important;
}

.opacity-2 {
    opacity: 0.4 !important;
}

.opacity-3 {
    opacity: 0.6 !important;
}

.opacity-4 {
    opacity: .8 !important;
}

.opacity-5 {
    opacity: 1 !important;
}

/* Maybe even support hover opacity shifts */
.opacity-0h5 {
    opacity: 0 !important;
    transition: opacity .25s ease-in-out !important;
    -moz-transition: opacity .25s ease-in-out !important;
    -webkit-transition: opacity .25s ease-in-out !important;
}

.opacity-0h5:hover {
    opacity: 1 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

p-calendar TH {
    text-align: center;
}

.filter-form fieldset, fieldset.fieldset-bordered {
    margin-bottom: 1rem;
    padding: 0 1rem;
    border: 1px solid #eee;
    border-radius: .5rem;
}

.filter-form legend, fieldset.fieldset-bordered legend {
    width: auto;
    padding: 0 .5rem;
    font-size: 1rem;
}

section {
    padding-top: 124px;
    margin-top: -124px;
}

.notify-dot {
    position: relative;
}

.notify-dot:after {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: -13px;
    right: -13px;
    background: #ffbc00;
    display: block;
    content: "";
    border: 2px #fff solid;
    transform: scale(1);
    animation: pulseNotifyDot 2s infinite;
}

@keyframes pulseNotifyDot {
    0% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.anchor-unstyled {
    text-decoration: none;
    color: inherit;
}

.anchor-unstyled:hover {
    color: inherit !important;
}

.content-fullscreen {
    margin-left: 60px !important;
}

.cursor-pointer {
    cursor: pointer;
}


.pdfViewer {
    padding: 1px;
}

.pdfViewerWrapper.isLoaded .ng2-pdf-viewer-container {
    position: relative !important;
}

.pdfViewer .page {
    margin: 20px auto 20px !important;
    -webkit-box-shadow: 0 0 0 1px #eee;
    -moz-box-shadow: 0 0 0 1px #eee;
    box-shadow: 0 0 0 1px #eee;
    border: none !important;
    /*margin: 15px 0 !important;*/
    /*border: 2px #eee solid !important;*/
    /*border-left: none !important;*/
    /*border-right: none !important;*/
}

.pdfViewer .annotationLayer .buttonWidgetAnnotation.pushButton > a,
.pdfViewer .annotationLayer .linkAnnotation > a {
    display: none !important;
}

.lds-ellipsis {
    z-index: 1000;
    width: 50px;
    height: 50px;
}

.lds-ellipsis div {
    position: absolute;
    top: 20px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #909598 !important;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 24px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 42px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(18px, 0);
    }
}

.table-nonhover-rows tr:hover {
    background-color: transparent !important;
}

.table-nonhover-rows tr {
    border-bottom: 1px solid #eee !important;
}

table.table td.td-highlight {
    background-color: #6979ed30;
}

ngb-modal-window.modal {
    z-index: 1055;
}

.welcome-modal-container .modal-backdrop {
    z-index: 1056 !important;
}

.welcome-modal-container .modal-dialog {
    z-index: 1057 !important;
}

.welcome-modal-container ngb-modal-window {
    z-index: 1056 !important;
}

.data-row .ng-select-td {
    overflow: unset;
}

.apexcharts-xaxis > .apexcharts-datalabel {
    fill: #adb5bd !important;
}

.swal2-icon.swal2-info:not(.swal2-icon-show-inactivity) {
    width: 60px !important;
    height: 60px !important;
}

.swal2-icon.swal2-info:not(.swal2-icon-show-inactivity) .swal2-icon-content {
    font-size: 3rem !important;
}


@media (min-width: 992px) and (hover: hover) {
    body.body-sidebar-collapsed .quantity-button, body.body-sidebar-collapsed .fixed-textinput {
        left: 60px;
    }
}


/**
    styles to work with page padding when modal or swall opened
 */
/* non touch devices */
@media (min-width: 992px) and (hover: hover) {
    body.modal-open .navbar-custom.topnav-navbar,
    .swal2-shown .navbar-custom.topnav-navbar {
        padding-right: 27px !important;
    }

    body.swal2-shown {
        padding-right: 0 !important;
    }
}

/* touch devices */
@media (min-width: 992px) and (hover: none) {
    body.modal-open .navbar-custom.topnav-navbar,
    body.modal-open .content-page,
    .swal2-shown .navbar-custom.topnav-navbar {
        padding-right: 12px !important;
    }
}

.content-page {
    margin-top: env(safe-area-inset-top) !important;
}

/**
    end styles to work with page padding when modal or swall opened
 */

/**/
.mdi-30px.mdi-set, .mdi-30px.mdi:before {
    font-size: 30px;
}

/* 2FA */
.two-factor-partitioned {
    letter-spacing: 38px;
    font-size: 28px;
    border: 0;
    outline: none;
    position: absolute;
    top: 0;
    padding: 0 0 0 15px;
    height: 58px;
    background: transparent;
    width: 340px;
}

.two-factor-partitioned:focus {
    background-color: transparent;
}

.two-factor-partitioned::selection {
    background: transparent;
}


.two-fa-wrapper {
    position: relative;
    width: 320px;
    margin: 0 auto;
}

.two-fa-wrapper .boxes {
    margin: 0;
}

.two-fa-wrapper .boxes .box {
    border: 2px solid #DFE2E6;
    height: 56px;
    width: 45px;
    border-radius: 5px;
    margin: 0 5px;
}

.two-fa-wrapper .boxes .box:first-child {
    margin-left: 0;
}

.two-fa-wrapper .boxes .box:last-child {
    margin-right: 0;
}

.two-fa-wrapper .boxes .box.selected {
    border: 3px solid rgb(105, 121, 237);
}

/* 2FA */

.bg-blur {
    backdrop-filter: blur(4px);
}

.no-row-hover app-data-table .table-wrap table tbody tr:hover {
    background-color: #ffffff !important;
}

.entity-indicator-dot {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0.2em 1em 0.2em 0;
    border-radius: 10px;
    display: block !important;
}

.avatar-box.active > .avatar-select {
    border-color: #a9a9a9 !important;
}

.avatar-wrapper {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px #eaeaea;
}

.avatar-wrapper img {
    max-width: 100%;
    /* max-height: 100%; remove max height so image will strech on full width; */
}

.microsoft-btn {
    display: block;
    align-items: center;
    height: 41px;
    background: #fff url('/assets/images/ms-symbollockup_mssymbol_19.svg') no-repeat 12px center;
    padding: 0 12px 0 45px;
    border: 1px solid #8c8c8c;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #5e5e5e;
}

.cdk-tree-node .tree-item .icon-wrapper {
    max-width: none;
}

body.public-page {
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-color: #fff;
    background-image: url('/assets/images/intro-foto-2.jpg');
}

@media (max-width: 991px) {
    body.public-page {
        background-size: 80%;
    }
}

@media (max-width: 768px) {
    body.public-page {
        background: none;
    }
}

.entrance {
    padding: 45px 0;
    flex-grow: 1;
}

.entrance__form__wrap {
    max-width: 420px;
    width: 100%;
    padding: 25px;
    background: rgba(255, 255, 255, .95);
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.entrance .rebranding {
    background: linear-gradient(157deg, #7e15ad, #5830d5);
    text-align: center;
    color: #fff;
    padding-bottom: 7px;
    padding-top: max(7px, env(safe-area-inset-top)) !important;
}

.rebranding .emoji {
    font-size: 1.45em;
    vertical-align: middle;
}

ngx-ui-loader .ngx-progress-bar.ngx-progress-bar-ltr {
    margin-top: env(safe-area-inset-top) !important;
}

.entrance__top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    z-index: 9;
    line-height: 20px;
}

.entrance .entrance__logo {
    padding: 30px 0;
    text-align: center;
}

.entrance .float-end {
    float: right;
}


.entrance .entrance__logo img {
    max-height: 55px;
    max-width: 270px;
}

.entrance .entrance__heading {
    margin-bottom: 20px;
}

.entrance .entrance__form {
    margin-top: 20px;
}

.entrance__main__wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.workspace__logo {
    max-height: 200px;
    max-width: 200px;
}

.entrance__navbar {
    width: 100vw;
    max-height: 70px;
    height: 70px;
    background: rgb(249, 249, 249);
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    padding: 0 10px 10px 10px;
    padding-top: max(10px, env(safe-area-inset-top)) !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.entrance__navbar div a img {
    width: 100px;
}

.entrance__navbar .entrance__navbar__action {
    text-align: right;
}

.entrance__navbar .entrance__navbar__action .mdi {
    font-size: 1.5em;
    vertical-align: middle;
}


.entrance__navbar .entrance__navbar__timeout .progress {
    height: 4px;
}

.entrance__navbar .entrance__navbar__timeout {
    border: 2px #eee solid;
    padding: 6px 15px;
    border-radius: 5px;
}

.entrance__navbar .entrance__navbar__timeout .progress__label {
    font-size: 12px;
}

.hidden-sensitive-value-checkbox label {
    visibility: hidden;
}

.hidden-sensitive-value-checkbox label:after {
    visibility: visible !important;
    -webkit-text-security: disc !important;
    text-security: disc !important;
    visibility: visible !important;
    content: "123456" !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    padding-left: 10px !important;
    background-color: transparent !important;
    width: auto !important;
    cursor: default !important;
}

.hidden-sensitive-value, .hidden-sensitive-value-wrapper {
    visibility: hidden;
    position: relative;
}

.hidden-sensitive-value-input {
    position: relative;
}

.hidden-sensitive-value-input input,
.hidden-sensitive-value-input .ng-select .ng-select-container {
    color: transparent;
}

.hidden-sensitive-value-input:after, .hidden-sensitive-value:after, .hidden-sensitive-value-wrapper:after {
    -webkit-text-security: disc;
    text-security: disc;
    visibility: visible;
    content: "123456";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.hidden-value {
    text-security: disc;
    -webkit-text-security: disc;
}

.hidden-sensitive-value-wrapper:after {
    top: 11px !important;
}

.hidden-sensitive-value-input:after {
    line-height: 45px;
    padding-left: 10px;
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef !important;
}

app-employee-attendance-chart, app-employee-shifts-chart, app-employee-attendance {
    position: relative;
}

.pointer-events-none {
    pointer-events: none;
}

.steps-progress {
    position: relative;
    padding-top: 22px;
}

.steps-progress .steps {
    display: flex;
    justify-content: space-between;
    counter-reset: stepsIndicatorCounter;
}

.steps-progress .steps-indicator {
    position: absolute;
    left: 12.5%;
    right: 12.5%;
    /*width: 100%;*/
    height: 6px;
    content: "";
    top: 0;
    background: #f9f9f9;
    border-radius: 5px;
}

.steps-progress .steps-indicator .steps-indicator-progress {
    position: absolute;
    left: 0;
    width: 33.33%;
    height: 6px;
    content: "";
    top: 0;
    border-radius: 5px;
    background: #6979ed !important;
}

.steps-progress .step {
    position: relative;
    counter-increment: stepsIndicatorCounter;
    line-height: 10px;
    height: 24px;
    width: 25%;
    text-align: center;
}

.steps-progress .step:before {
    position: absolute;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    content: counter(stepsIndicatorCounter);
    display: block;
    z-index: 10;
    border: #e5e5e5 1px solid;
    border-radius: 50%;
    top: -32px;
    background: white;
    font-size: 0.8em;
}

.steps-progress .step-done:before {
    background: #6979ed !important;
    color: white;
    border-color: #697ded !important;
}

.steps-progress .step-done {
    color: black;
}


.steps-progress .step span {
    background: rgba(255, 255, 255, .5);
    border-radius: 4px;
    padding: 0 10px;
    line-height: 18px;
    height: 18px;
    display: inline-block;
    width: 100%;
}

.steps-progress .steps-indicator {
    z-index: 0;
}

.entrance__steps__progress {
    margin-top: 15px;
    min-width: 50%;
}

asl-google-signin-button > div > div > div {
    border: 0 !important;
    background: transparent !important;
}

asl-google-signin-button > div > div > div:hover > div {
    background: transparent !important;
}

asl-google-signin-button > div > div > div > div > span {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #5e5e5e !important;
}

.entity-name-clickable:hover {
    cursor: pointer;
    text-decoration: underline;
    color: inherit !important;
}

.entity-name-clickable {
    text-decoration: none;
    color: inherit;
}

.entity-indicators {
    position: absolute;
    left: -15px;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.media, .entity-indicators-wrapper {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    position: relative;
    overflow: visible;
}

body:not(.public-page) #credential_picker_container {
    display: none !important;
}

.text-cropped {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-extreme {
    color: #b61f66;
}

div.autocomplete-container {
    box-shadow: none;
    height: 45px;
}

ng-autocomplete .input-container input {
    height: 45px !important;
    border: 2px solid #DFE2E6 !important;
    border-radius: 3px !important;
    color: #000 !important;
    font-size: 16px !important;
}

.autocomplete-container .suggestions-container {
    width: calc(100% - 4px) !important;
}

#suggestions {
    margin: 0 2px !important;
}

#suggestions > ul > li.item, #suggestions > ul > li.item > div, #suggestions > ul > li.item > div > a {
    height: 45px;
}

.showed-on-hover {
    display: none !important;
}

.show-button-on-hover:hover button.showed-on-hover {
    display: block !important;
}


.card-body-icon-bg {
    position: relative;
    overflow: hidden;
    padding: 1.2rem 1.2rem;
}

.card-body-icon-bg .row {
    position: relative;
    z-index: 2;
}

.card-body-icon-bg-icon {
    width: 120px;
    height: 120px;
    text-align: left;
    line-height: 120px;
    display: block;
    position: absolute;
    font-size: 120px;
    right: -36px;
    top: 50%;
    margin-top: -60px;
    z-index: 1;
    opacity: .055;
    color: #000;
    transition: all .3s;
}

.card-bg-icon-value {
    text-align: left;
    font-size: 2.5em;
    font-weight: 700;
}

.card-bg-icon {
    cursor: pointer;
}

.card-bg-icon:hover .card-body-icon-bg-icon {
    color: #6979ED;
    opacity: .25;
}

.card-bg-icon:not(:hover) .card-bg-icon-value {
    color: #666 !important;
}

.search-open .topnav-navbar {
    z-index: 1056 !important;
}

span.pdf-annotation {
    cursor: pointer;
    background-color: white;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 11pt;
    color: black;
    position: absolute;
    display: block;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 0 3px;
}


.help-sidebar-item .help-sidebar-item-description p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    display: block;
}


/* center value in input
- need to be sure, height is 24px
*/
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 50%;
    margin-top: -12px;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

.employee-state-1 {
    color: black;
    background-color: #6979ed;;
}

.employee-state-2 {
    color: black;
    background-color: rgba(218, 142, 54, 0.5);
}

.employee-state-3 {
    color: black;
    background-color: rgba(245, 97, 9, 0.7);
}

.employee-state-4 {
    color: black;
    background-color: rgba(244, 69, 73, .5);
}

.employee-state-5 {
    color: black;
    background-color: rgba(215, 2, 20, .5);
}

.employee-state-6 {
    color: black;
    background-color: rgba(215, 2, 20, .5);
}

#from-to-error {
    margin-top: -5px;
    margin-bottom: 10px;
}

.ai-help-tooltip .tooltip-inner {
    width: 400px;
    max-width: 400px;
    text-align: left;
}

.impersonation-tooltip {
    z-index: 20;
}

div.table-compact table td {
    padding-bottom: 0;
    padding-top: 0;
}

input::-webkit-date-and-time-value {
    text-align: left !important;
}

input.flatpickr-mobile {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}

.alert {
    border-width: 2px;
}

.alert-warning {
    color: #856200;
    background-color: rgb(255, 220, 120);
    border-color: rgb(244, 211, 118);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05) !important;
}

.alert-warning.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05) !important;
}

table.table-bordered-2px {
    border: 2px solid #DFE2E6;
    border-radius: .25rem;
}

.table-bordered-2px td {
    border: 2px solid #DFE2E6;
}

.td-input-wrapper {
    padding: 7px;
}

input.invisible-border {
    background: #f9f9f9f9;
    border: none;
}

input.low {
    height: 36px !important;
}

.echart-tooltip {
    z-index: auto !important;
}

.comment-text img {
    max-width: 100%;
}

.entrance__wrapper {
    margin-top: env(safe-area-inset-top) !important;
}

.btn--ai {
    color: #5830d5;
    background: white;
    border: 2px solid #5830d5;
    border-radius: .15rem;
}

.btn--ai:hover {
    background: linear-gradient(157deg, #7e15ad, #5830d5);
    color: white;
}

.ai-label i {
    color: #5830d5;
}

div#sidebar-help.help-list {
    height: calc(100% - 75px) !important;
    max-width: 400px;
    width: 100% !important;
    position: fixed !important;
    right: 0 !important;
    top: 75px !important;
    padding: 10px;
    border: 0 !important;
    margin-top: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.help-sidebar-content {
    position: relative;
    height: calc(100% - 48px);
    overflow-y: auto;
}

.help-sidebar-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.075);
    -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.075);
    box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.075);
}

.help-sidebar-title {
    margin: 0 0 0;
    padding: 15px;
    font-weight: 700;
    font-size: 17px;
    color: #000;
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.help-sidebar-category {
    margin: 0;
    padding: 15px;
    font-weight: 700;
    font-size: 1em;
    color: #000;
    cursor: default;
}

.help-sidebar-item {
    color: rgb(108, 117, 125);
    padding: 10px 15px;
    display: block;
    border-top: 1px #f6f6f6 solid;
}

.help-sidebar-item:hover {
    background: #f8f9fa;
}

.help-sidebar-item .help-sidebar-item-title {
    font-weight: 700;
}

.help-sidebar-item .help-sidebar-item-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .85em;
}

.help-sidebar-item .help-sidebar-item-description p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    display: block;
}

@media (max-width: 767px) {
    div#sidebar-help.help-list {
        height: calc(100% - 70px) !important;
        top: 70px !important;
        max-width: 100% !important;
    }
}

@media (max-width: 578px) {

    /**
    grid customization
    */
    .card {
        margin-bottom: 12px;
    }

    .row {
        margin-left: -6px;
        margin-right: -6px;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        padding-left: 6px;
        padding-right: 6px;
    }

    /**
    other responsive customizations
    */
    .card-body-icon-bg {
        padding: .75rem;
    }

    .card-bg-icon-value {
        font-size: 2.25em;
    }

    .card-body-icon-bg-icon {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 80px;
        right: -20px;
        margin-top: -40px;
    }

}

input[mwlflatpickr] + input[type="text"] {
    background: white;
}

.swal2-icon.swal2-icon-loader {
    border: none;
}

.spinner-border-light {
    border-width: .15em;
}

.spinner-border-slow {
    animation-duration: 1s;
}

.underline-on-hover:hover {
    text-decoration: underline;
    cursor: pointer;
}

div.fc-harness-loading.fc-view-harness.fc-view-harness-active {
    height: 0 !important;
    visibility: hidden !important;
}

a.full-calendar-duplicated-event {
    border: 2px solid black !important;
    background-color: gray !important;
}

#event-card .card .card-body #dashboard-events-row {
    max-height: 100%;
    overflow: auto;
}

/* table layout */

@media (max-width: 575px) {
    .modal-dialog {
        margin-top: max(.5rem, env(safe-area-inset-top)) !important;
        margin-bottom: max(.5rem, env(safe-area-inset-bottom)) !important;
    }

    .table-compact-1 {
        tr {
            td:not(:last-child):not(:nth-child(1)) {
                display: none;
            }

            th:not(:last-child):not(:nth-child(1)) {
                display: none;
            }
        }

        colgroup {
            col:not(:last-child):not(:nth-child(1)) {
                display: none;
            }
        }
    }

    .table-compact-1-2 {
        tr {
            td:not(:last-child):not(:nth-child(1)):not(:nth-child(2)) {
                display: none;
            }

            th:not(:last-child):not(:nth-child(1)):not(:nth-child(2)) {
                display: none;
            }
        }

        colgroup {
            col:not(:last-child):not(:nth-child(1)):not(:nth-child(2)) {
                display: none;
            }
        }
    }

    .table-compact-1-2-3 {
        tr {
            td:not(:last-child):not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                display: none;
            }

            th:not(:last-child):not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                display: none;
            }
        }

        colgroup {
            col:not(:last-child):not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                display: none;
            }
        }
    }

    .table-compact-2 {
        tr {
            td:not(:last-child):not(:nth-child(2)) {
                display: none;
            }

            th:not(:last-child):not(:nth-child(2)) {
                display: none;
            }
        }

        colgroup {
            col:not(:last-child):not(:nth-child(2)) {
                display: none;
            }
        }
    }
}

@media (min-width: 576px) {
    app-data-table {
        div.table-wrap {
            overflow: auto;
        }

        table.table {
            table-layout: unset !important;

            &.table-hover tbody tr td {
                background-color: #fff;
            }

            &.table-hover tbody tr:hover td {
                color: #6c757d;
                background: #f1f3fa;
            }

            th {
                background: #fff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            th.sortable:hover {
                color: #6c757d;
                background: #f1f3fa;
            }

            &.table-compact-1,
            &.table-compact-1-2,
            &.table-compact-1-2-3 {
                td:last-child, th:last-child {
                    z-index: 1;
                    white-space: nowrap;
                    position: sticky;
                    right: 0;
                    /*filter: drop-shadow(-2px 2px 4px #eeeeee);
                    -webkit-box-shadow: -2px 2px 4px #eeeeee;
                    box-shadow: -2px 2px 4px #eeeeee;*/
                }

                td:not(first-child):not(last-child), th:not(first-child):not(last-child) {
                    max-width: 160px;
                }
            }

            &.table-compact-1 {
                tr {
                    td:first-child, th:first-child {
                        overflow: hidden;
                        z-index: 1;
                        white-space: nowrap;
                        max-width: 400px;
                        position: sticky;
                        left: 0;
                        /*filter: drop-shadow(2px 2px 4px #eeeeee);
                        -webkit-box-shadow: 2px 2px 4px #eeeeee;
                        box-shadow: 2px 2px 4px #eeeeee;*/
                    }
                }
            }

            &.table-compact-1-2 {
                tr {
                    td:first-child,
                    th:first-child {
                        left: 0;
                        width: 50px;
                    }

                    td:nth-child(2),
                    th:nth-child(2) {
                        left: 50px;
                        max-width: 400px;
                        /*filter: drop-shadow(2px 2px 4px #eeeeee);
                        -webkit-box-shadow: 2px 2px 4px #eeeeee;
                        box-shadow: 2px 2px 4px #eeeeee;*/
                    }


                    td:first-child, td:nth-child(2), th:first-child, th:nth-child(2) {
                        overflow: hidden;
                        z-index: 1;
                        white-space: nowrap;
                        position: sticky;
                    }
                }
            }

            &.table-compact-1-2-3 {


                /**

                dochazka - checkbox, jmeno, datum
                activity log - checkbox, datum, typ
                list majetku - checkbox, kategorie, nazev
                */

                tr {

                    td:first-child,
                    th:first-child {
                        left: 0;
                        width: 50px;
                    }

                    td:nth-child(2),
                    th:nth-child(2) {
                        left: 50px;
                        width: 160px;
                    }


                    td:nth-child(3),
                    th:nth-child(3) {
                        left: 210px;
                        max-width: 400px;
                        /*filter: drop-shadow(2px 2px 4px #eeeeee);
                        -webkit-box-shadow: 2px 2px 4px #eeeeee;
                        box-shadow: 2px 2px 4px #eeeeee;*/
                    }

                    td:first-child, td:nth-child(2), td:nth-child(3), th:first-child, th:nth-child(2), th:nth-child(3) {
                        overflow: hidden;
                        z-index: 1;
                        white-space: nowrap;
                        position: sticky;
                        /*filter: drop-shadow(2px 2px 4px #eeeeee);
                        -webkit-box-shadow: 2px 2px 4px #eeeeee;
                        box-shadow: 2px 2px 4px #eeeeee;*/
                    }


                }
            }
        }
    }
}
